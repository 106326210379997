import React, { useEffect, useState } from 'react'
import { Feedback } from 'app-principal-componentes-visuales';
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { timeoutCerrarSession } from '../../../helpers/WebYsocial/sesionHelper';
import { ASISTENCIA_CREADA_YSOCIAL, BOTON_FEEDBACK_YSOCIAL, PARRAFO_ERROR_GENERICO, TITULO_FEEDBACK } from '../../../utils/constants';
import { saveYoizen } from '../../../api/mensajeriaService';
import { useRequest } from '../../../hooks/webUseRequest';
import "./AsistenciaSolicitadaYsocial.scss"

const AsistenciaSolicitadaYsocial = () => {

    const { apiRequest } = useRequest();
    const [isAsistenciaValida, setIsAsistenciaValida] = useState(true);
    const ysocialStorage = JSON.parse(sessionStorage.getItem("ysocial"));

    useEffect(() => {
        handleYsocial();
    }, [])

    const handleYsocial = () => {
        const message = ASISTENCIA_CREADA_YSOCIAL;
        saveYoizen(ysocialStorage.ysocialToken, message, 200, apiRequest)
            .then(() => {
                console.info("Se realizo la petición a yoizen correctamente");
            }).catch(error => {
                console.error("Error mensaje POST WhatsApp", error);
                setIsAsistenciaValida(false);
            }).finally(async () => {
                await timeoutCerrarSession();
            });
    }

    return (
        <IonPage>
            <IonContent>
                <div className="ysocial-background-page">
                    <IonGrid className='ysocial_content-container ysocial-padding'>
                        <IonRow>
                            <IonCol size='12'>
                                {
                                    isAsistenciaValida
                                        ?
                                        <Feedback
                                            notOsde
                                            asistencia
                                            handsPhoneReady
                                            waiting
                                            labelButtonOk={BOTON_FEEDBACK_YSOCIAL}
                                            onClickOk={() => {}}
                                            parrafo={`Tu solicitud fue gestionada. Para acceder al link de la consulta volve al WhatsApp. Al hacerlo se cerrará la sesión.`}
                                            titulo='Asistencia médica solicitada'
                                            id="feedback-asistencia-solicitada-ysocial"
                                        />
                                        :
                                        <Feedback
                                            notOsde
                                            titulo={TITULO_FEEDBACK}
                                            parrafo={PARRAFO_ERROR_GENERICO}
                                            handsPhoneExclamation
                                            waiting
                                            labelButtonOk={BOTON_FEEDBACK_YSOCIAL}
                                            onClickOk={() => {}}
                                            id="feedback-ysocial"
                                        />
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default AsistenciaSolicitadaYsocial;