import axios from 'axios';
import { clearAllStorage } from "../dataProvider";
import { Environment } from '../../environment/Environment';
import { AUTH_SERVICE_URL, REVOKE, TELEFONO_WHATSAPP, TIMEOUT_YSOCIAL } from "../../utils/constants";

export const cerrarSesion = async () => {

    const urlRevoke = `${Environment.ENV.BACKEND_ENDPOINT}${AUTH_SERVICE_URL}${REVOKE}`;
    const accessToken = sessionStorage.getItem('access_token');
    const headers = { "Content-Type": "application/json" }
    try {
        console.info("Entre al try del sesionHelper");
        const responseData = await axios.post(urlRevoke, { token: accessToken }, { headers: headers });
        if (responseData.status === 200) {
            console.info("Token revocado");
        }
    }
    catch (error) {
        console.error("Error: revocarToken", { error });
    }
    finally {
        clearAllStorage();
        sessionStorage.removeItem('expires_in');
        sessionStorage.removeItem('refresh_token');
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('codeVerifier');
        sessionStorage.removeItem('codeChallenge');
        sessionStorage.removeItem("ysocial");
        deleteAllCookies();

        let redirectTo = `&redirectTo=https://wa.me/549${TELEFONO_WHATSAPP()}`;
        const urlAuthIAMLogout = Environment.ENV.LOGOUT_URL + Environment.ENV.THEME_ID + redirectTo;
        window.location.replace(urlAuthIAMLogout);
    }
};

const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    cookies.forEach((cookie) => {
        const name = cookie.split('=')[0].trim();
        document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${window.location.hostname}; Secure; SameSite=None`;
    });
};

export const timeoutCerrarSession = () => {
    setTimeout(() => cerrarSesion(), TIMEOUT_YSOCIAL);
}