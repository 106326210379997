import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react'
import { Titulo, Button, Subtitulo, Header } from 'app-principal-componentes-visuales'
import React from 'react'
import { TELEFONO_URGENCIAS_UNO, TELEFONO_URGENCIAS_DOS, LABEL_RIESGO, TELEFONO_EMERGENCIAS } from '../../../utils/constants'
import { formatearNumeroBoton } from '../../../utils/utils'
import './TelefonosPageYsocial.scss'

const TelefonosPageYsocial = () => {

    return (
        <IonPage>
            <Header
                titulo='Contactanos'
                colorWhite
                fondoCeleste
                headerTramites
                id="header-ysocial-web-header"
            />
            <IonContent fullscreen>
                <IonGrid className='telefonos__page__ysocial content__grid asistencia_content-container'>
                    <IonRow>
                        <IonCol size='12'>
                            <Titulo titulo="Para brindarte una mejor asistencia, será necesario que te contactes directamente con nosotros." id="titulo-asistencia-telefono-page" />
                        </IonCol>
                    </IonRow>
                    <IonRow className={'contactanos-row'}>
                        <IonCol size='12'>
                            <Button
                                id='urgencias-uno-button'
                                href={`tel:${TELEFONO_URGENCIAS_UNO()}`}
                                label={formatearNumeroBoton(TELEFONO_URGENCIAS_UNO()!)}
                                phone
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className={'contactanos-row'}>
                        <IonCol size='12'>
                            <Button
                                id='urgencias-2-button'
                                href={`tel:${TELEFONO_URGENCIAS_DOS()}`}
                                label={formatearNumeroBoton(TELEFONO_URGENCIAS_DOS()!)}
                                phone
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className={'contactanos-row'}>
                        <IonCol size='12'>
                            <Subtitulo
                                titulo={LABEL_RIESGO}
                                txtRed
                                id="subtitulo-riesgo"
                            />
                            <Button
                                colorRed
                                id='Riesgo-button'
                                href={`tel:${TELEFONO_EMERGENCIAS()}`}
                                label={formatearNumeroBoton(TELEFONO_EMERGENCIAS()!)}
                                primary
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default TelefonosPageYsocial