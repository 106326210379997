import { CABA, CAPITAL, CODIGO_CANAL_ENTRADA, CODIGO_CANAL_ENTRADA_YSOCIAL, FIRMA_SALUS, PREFIJO_MOTIVO_YSOCIAL } from "../../utils/constants";
import { CrearDerivarCasoYsocial, ListaContactos, PreguntaCaso, ServiciosMultiplesResponse } from "../../interfaces/ysocial/crearDerivarCasoYsocial.interface";
import { InfoCaso } from "../../models/caso.model";
import { Socio } from "../../models/socioPrincipal.model";
import { SocioFamiliar } from "../../interfaces/socioFamiliar.interface";

export const cargarDatosYsocial = async (icLogin, usuario, sintoma, direccion, preguntas, clasificacion): Promise<CrearDerivarCasoYsocial> => {
    const { calle, altura, provincia, localidad, barrio, observaciones } = direccion;

    const firma = FIRMA_SALUS;
    const codigoCanalEntrada = CODIGO_CANAL_ENTRADA;
    // TODO Cambiar de canal cuando la 3.6 este ya en productivo
    // const codigoCanalEntrada = CODIGO_CANAL_ENTRADA_YSOCIAL;
    const numeroIcLogin = icLogin.toString();

    let listacontactos: ListaContactos[] = [];
    const capitalFederal = provincia === CABA ? CAPITAL : provincia;
    const motivos = sintoma.descripcion;

    let contacto: ListaContactos = {
        telefonollamante: usuario.telefono,
        numeroic: usuario.ic.toString(),
        numerosocio: usuario.nroContrato,
        email: usuario.email,
        documento: usuario.dni,
        calle,
        numero: altura,
        provincia: capitalFederal,
        localidad,
        barrio,
        observaciones,
        codigoTipoServicio: clasificacion?.servicio.descripcion,
        codigoSubtipoServicio: clasificacion?.descripcion,
        motivo: `${PREFIJO_MOTIVO_YSOCIAL}${motivos}`
    };

    listacontactos.push(contacto);

    const anotaciones = generarAnotaciones(preguntas);

    const casoSend: CrearDerivarCasoYsocial = {
        firma,
        codigoCanalEntrada,
        numeroIc: numeroIcLogin,
        listacontactos,
        listaanotaciones: [anotaciones]
    };

    return casoSend;
};


const generarAnotaciones = (preguntas: PreguntaCaso[]): { anotaciones: string[] } => {
    const anotaciones = preguntas.flatMap(({ descripcion, respuesta }) => [
        `P: ${descripcion}`,
        `R: ${respuesta}`,
    ]);

    return { anotaciones };
};

/**
 * Metodo que se encarga de crear un objeto infoCaso
 */
export const crearInfoCasoYsocial = async (usuario, sintomaFinal, direccionSeleccionada, sendCasoResponse: ServiciosMultiplesResponse) => {

    let infoCasos: InfoCaso[] = [];
    const casosValidos = sendCasoResponse.casos.filter(caso => caso.estatus === 200);

    if (casosValidos[0]) {
        let infoCaso = new InfoCaso();
        let socioPrincipal = usuario;

        infoCaso.principal = socioPrincipal;
        infoCaso.sintomas = sintomaFinal.descripcion;
        infoCaso.observacionSintoma = "";

        let dirAux = direccionSeleccionada.provincia === CABA ? CAPITAL : direccionSeleccionada.provincia;
        let direccion = direccionSeleccionada;
        direccion = { ...direccion, provincia: dirAux }

        let direccionString = `${direccion.calle} ${direccion.altura}${direccion.barrio ? ", " + direccion.barrio + ", " : ", "} ${direccion.localidad}, ${direccion.provincia}`;
        infoCaso.domicilio = direccionString;

        if (isSocioPrincipal(usuario)) {
            infoCaso.email = socioPrincipal.email;
            infoCaso.telefono = socioPrincipal.telefono;
        } else {
            infoCaso.familiar = usuario;
            infoCaso.email = usuario.email;
            infoCaso.telefono = usuario.telefono;
        }

        let datosCaso = casosValidos[0];
        infoCaso.nroCaso = datosCaso.servicio.codigo;
        infoCaso.codigoContacto = datosCaso.servicio.codigocontacto;
        infoCaso.codigoTipoServicio = datosCaso.servicio.codigotiposervicio;
        infoCaso.codigoSubTipoServicio = datosCaso.servicio.codigosubtiposervicio;
        infoCaso.estado = datosCaso.servicio.estado;
        let fechaCreacionToString = datosCaso.servicio.horacreacion.toString().slice(0, 19);
        let fechaCreacion = fechaCreacionToString.concat('.000+00:00').replace(/ /g, "T");
        infoCaso.fechaCreacion = new Date(fechaCreacion);

        let contratoActivo = JSON.parse(localStorage.getItem('contratoActivo'));

        if (contratoActivo) {
            infoCaso.contrato = usuario.nroContrato;
            infoCaso.fechaInicioContrato = contratoActivo.fechaInicioContrato;
            infoCaso.fechaFinContrato = contratoActivo.fechaFinContrato;
            infoCaso.fechaInicioVigencia = contratoActivo.fechaInicioVigencia;
            infoCaso.fechaFinVigencia = contratoActivo.fechaFinVigencia;
            infoCaso.estadoContrato = contratoActivo.estado;
        }

        infoCasos.push(infoCaso);
    } 

    const casosValidosFiltrados = infoCasos.filter(caso => caso !== null);

    return casosValidosFiltrados;
}

export const cargarDatosBd = async (sendCasoResponse, infoCaso, usuario, dirSeleccionada, sintomaFinal) => {
    let socioPrincipal = usuario;

    infoCaso.principal = socioPrincipal;
    infoCaso.sintomas = sintomaFinal.descripcion;
    infoCaso.observacionSintoma = "";

    let direccion = dirSeleccionada;
    let direccionString = `${direccion.calle} ${direccion.altura}${direccion.barrio ? ", " + direccion.barrio + ", " : ", "} ${direccion.localidad}, ${direccion.provincia}`;
    infoCaso.domicilio = direccionString;

    infoCaso.familiar = usuario;
    infoCaso.email = usuario.email;
    infoCaso.telefono = usuario.telefono;

    if (sendCasoResponse && sendCasoResponse.data && sendCasoResponse.data.servicio) {
        infoCaso.nroCaso = sendCasoResponse.data.servicio.codigo;
        infoCaso.codigoContacto = sendCasoResponse.data.servicio.codigocontacto;
        infoCaso.codigoTipoServicio = sendCasoResponse.data.servicio.codigotiposervicio;
        infoCaso.codigoSubTipoServicio = sendCasoResponse.data.servicio.codigosubtiposervicio;
        infoCaso.estado = sendCasoResponse.data.servicio.estado;
        let fechaCreacionToString = sendCasoResponse.data.servicio.horacreacion.toString().slice(0, 19);
        let fechaCreacion = fechaCreacionToString.concat('.000+00:00').replace(/ /g, "T");
        infoCaso.fechaCreacion = new Date(fechaCreacion);
    }

    let contratoActivo = await JSON.parse(localStorage.getItem('contratoActivo'));

    if (contratoActivo) {
        infoCaso.contrato = usuario.nroContrato;
        infoCaso.fechaInicioContrato = contratoActivo.fechaInicioContrato;
        infoCaso.fechaFinContrato = contratoActivo.fechaFinContrato;
        infoCaso.fechaInicioVigencia = contratoActivo.fechaInicioVigencia;
        infoCaso.fechaFinVigencia = contratoActivo.fechaFinVigencia;
        infoCaso.estadoContrato = contratoActivo.estado;
    }

    return infoCaso
}

/**
 * Comprueba si el socio es de tipo Socio o SocioFamiliar
 * @param socio 
 */
const isSocioPrincipal = (socio: Socio | SocioFamiliar): socio is Socio => {
    return (socio as Socio).contratos !== undefined;
};
