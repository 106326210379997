import React, { useEffect, useState } from "react";
import { Feedback } from "app-principal-componentes-visuales";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { BOTON_FEEDBACK_YSOCIAL, GRUPO_FAMILIAR_ERROR_YSOCIAL, PARRAFO_ERROR_GENERICO, TITULO_FEEDBACK } from "../../../utils/constants";
import { timeoutCerrarSession } from "../../../helpers/WebYsocial/sesionHelper";
import { useRequest } from "../../../hooks/webUseRequest";
import { saveYoizen } from "../../../api/mensajeriaService";
import "./FeedbackYsocial.scss";

interface FeedbackYsocialProps {
    titulo: string,
    parrafo: string,
    message: string
}

const FeedbackYsocial = ({ titulo, parrafo, message }: FeedbackYsocialProps) => {

    const { apiRequest } = useRequest();
    const [yoizen, setYoizen] = useState(true);
    const ysocial = JSON.parse(sessionStorage.getItem("ysocial"));

    useEffect(() => {
        const statusError = (message === GRUPO_FAMILIAR_ERROR_YSOCIAL) ? 404 : 412;
        if (statusError) returnToWhatsapp(statusError);
    }, [message])

    const returnToWhatsapp = (statusError) => {
        saveYoizen(ysocial.ysocialToken, message, statusError, apiRequest)
            .then(() => {
                console.info("Se realizo la petición a yoizen correctamente");
            }).catch(error => {
                console.error("Error mensaje POST WhatsApp", error);
                setYoizen(false);
            }).finally(async () => {
                await timeoutCerrarSession();
            });
    }

    return (
        <IonPage>
            <IonContent>
                <div className="ysocial-background-page">
                    <IonGrid className="ysocial_content-container ysocial-padding">
                        <IonRow>
                            <IonCol size='12'>
                                { yoizen ?
                                    <Feedback
                                        notOsde
                                        waiting
                                        titulo={titulo}
                                        parrafo={parrafo}
                                        handsPhoneExclamation
                                        labelButtonOk={BOTON_FEEDBACK_YSOCIAL}
                                        onClickOk={() => {}}
                                        id="feedback-ysocial"
                                    />
                                    :
                                    <Feedback
                                        notOsde
                                        waiting
                                        titulo={TITULO_FEEDBACK}
                                        parrafo={PARRAFO_ERROR_GENERICO}
                                        handsPhoneExclamation
                                        labelButtonOk={BOTON_FEEDBACK_YSOCIAL}
                                        onClickOk={() => {}}
                                        id="feedback-ysocial"
                                    />
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default FeedbackYsocial;