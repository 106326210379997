import React, { useEffect, useState } from 'react'
import { IonGrid, IonRow, IonCol, IonContent, IonPage } from '@ionic/react';
import { Feedback } from 'app-principal-componentes-visuales';
import { useRequest } from '../../../hooks/webUseRequest';
import { saveYoizen } from '../../../api/mensajeriaService';
import { timeoutCerrarSession } from '../../../helpers/WebYsocial/sesionHelper';
import { ASISTENCIA_ERROR_YSOCIAL, BOTON_FEEDBACK_YSOCIAL, PARRAFO_ERROR_GENERICO, TITULO_FEEDBACK } from '../../../utils/constants';
import "./ErrorTecnicoYsocial.scss";

const ErrorTecnicoYsocial = () => {

    const { apiRequest } = useRequest();
    const [yoizen, setYoizen] = useState(true);
    const ysocial = JSON.parse(sessionStorage.getItem("ysocial"));

    useEffect(() => {
        returnToWhatsapp();
    }, [])
    
    const returnToWhatsapp = () => {
        const message = ASISTENCIA_ERROR_YSOCIAL;
        saveYoizen(ysocial.ysocialToken, message, 500, apiRequest)
            .then(() => {
                console.info("Se realizo la petición a yoizen correctamente");
            }).catch(error => {
                console.error("Error mensaje POST WhatsApp", error);
                setYoizen(false);
            }).finally(async () => {
                await timeoutCerrarSession();
            });
    }

    return (
        <IonPage>
            <IonContent>
                <div className="ysocial-background-page">
                    <IonGrid className="ysocial_content-container ysocial-padding">
                        <IonRow>
                            <IonCol size='12'>
                                {
                                    yoizen ?
                                        <Feedback
                                            notOsde
                                            waiting
                                            id='problema-tecnico'
                                            titulo={"Tuvimos un problema técnico"}
                                            parrafo={"Estamos trabajando para resolverlo. Podés contactarnos a través de WhatsApp o llamada telefónica"}
                                            handsPhoneError
                                            labelButtonOk={BOTON_FEEDBACK_YSOCIAL}
                                            onClickOk={() => {}}
                                        />
                                        :
                                        <Feedback
                                            notOsde
                                            waiting
                                            titulo={TITULO_FEEDBACK}
                                            parrafo={PARRAFO_ERROR_GENERICO}
                                            handsPhoneExclamation
                                            labelButtonOk={BOTON_FEEDBACK_YSOCIAL}
                                            onClickOk={() => {}}
                                            id="feedback-ysocial"
                                        />
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default ErrorTecnicoYsocial